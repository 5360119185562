import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { ArticleData } from 'models';
import { RichTextViewer } from 'components/rich-text-viewer';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ArticleHeader from './article-header';

interface ArticleProps {
  article: ArticleData;
}
const Article: React.FC<ArticleProps> = ({ article }) => (
  <>
    <ArticleHeader article={article} />
    <div>
      <RichTextViewer rawContent={article.content} />
      <Divider />
      <Typography.Title level={2}>Articles similaires</Typography.Title>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {article.related_articles?.map((current) => (
          <Col key={current.slug} md={8} sm={8} xs={24}>
            <Link to={`/blog/${current.slug}`}>
              <GatsbyImage
                alt={`Cover of ${current.title} article`}
                image={getImage(current.image.localFile)}
                style={{ marginBottom: `12px` }}
              />
              <Typography.Title level={5}>{current.title}</Typography.Title>
              <Typography.Paragraph type="secondary">
                {current.description}
                {` `}
                <Typography.Paragraph
                  style={{ display: `inline`, color: `#B35A18` }}
                >
                  Lire <FontAwesomeIcon icon={faArrowRight} />
                </Typography.Paragraph>
              </Typography.Paragraph>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  </>
);

export default Article;
