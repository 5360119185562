import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import { ArticleData, JourneyStep, SeoData } from 'models';
import { graphql, PageProps } from 'gatsby';
import Article from '@/components/article';

interface ArticlePageProps {
  article: ArticleData;
  journeySteps: JourneyStep[];
}
const ArticlePage: React.FC<PageProps<ArticlePageProps>> = ({ data }) => {
  const { article } = data;
  const seo: SeoData = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    ogType: `article`,
  };

  return (
    <Layout expand={false}>
      <Seo seo={seo} />
      <Article article={article} />
    </Layout>
  );
};

export const query = graphql`
  query ArticlePageQuery($slug: String!) {
    article: strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      published_at(locale: "fr", formatString: "DD MMMM YYYY")

      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER }
            )
          }
        }
      }

      related_articles {
        slug
        title
        description
        published_at(locale: "fr", formatString: "DD MMMM YYYY")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                height: 150
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER }
              )
            }
          }
        }
      }
    }
  }
`;
export default ArticlePage;
