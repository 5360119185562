import React from 'react';
import { Typography } from 'antd';
import { ArticleData } from 'models';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useMediaQuery from 'use-media-antd-query';

interface ArticleHeaderProps {
  article: ArticleData;
}
const ArticleHeader: React.FC<ArticleHeaderProps> = ({ article }) => {
  const colSize = useMediaQuery();

  return (
    <div
      style={{
        width: `100%`,
        position: `relative`,
        overflow: `hidden`,
        marginBottom: `20px`,
      }}
    >
      <div
        style={{
          display: `flex`,
          alignItems: `flex-start`,
          position: `absolute`,
          justifyContent: `center`,
          left: `0`,
          right: `0`,
          top: `0`,
          bottom: `0`,
          width: `100%`,
          height: `100%`,
          zIndex: 1,
          ...(colSize === `xs` && {
            position: `relative`,
            height: `fit-content`,
          }),
        }}
      >
        <div
          style={{
            marginTop: colSize === `xs` ? 0 : `40px`,
            background: colSize === `xs` ? `inherit` : `white`,
            textAlign: `center`,
            padding: `20px 15px`,
            maxWidth: colSize === `xs` ? `100%` : `80%`,
          }}
        >
          <Typography.Title level={1}>{article.title}</Typography.Title>

          <Typography.Paragraph type="secondary" italic style={{ margin: `0` }}>
            {article.published_at}
          </Typography.Paragraph>
          <Typography.Paragraph>{article.description}</Typography.Paragraph>
        </div>
      </div>
      <GatsbyImage
        style={{
          position: colSize === `xs` ? `relative` : `initial`,
          height: `80vh`,
        }}
        alt={`Cover of ${article.title} article`}
        image={getImage(article.image.localFile)}
        imgStyle={{ objectPosition: `center` }}
      />
    </div>
  );
};

export default ArticleHeader;
